import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://00bbe9a8755a80fb548158c00889d8db@o4507132418457600.ingest.de.sentry.io/4507134722441296",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,
});